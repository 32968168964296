<template>
  <div class="main-contents">
    <div class="content-view">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">联系我们</div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <div class="left">
          <leftItem v-for="(item,index) in list" :key="index" :index="Number(isWe)" :cur="index" :item="item" @cilckMethod="cilckMethod"></leftItem>
        </div>
        <div class="right">
          <div class="one" v-if="itemData != null" v-html="itemData.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftItem from '../aboutUs/components/leftItem-box'
export default {
	components:{leftItem},
	data() {
		return {
			itemData:{},
			list:[{title:'读者服务'},{title:'经销商服务'},{title:'人才招聘'},{title:'作译者服务'},{title:'法律声明'},{title:'盗版举报'}],
		}
	},
	created(){
		this.$store.commit('changClick',1)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.getContactUs(Number(this.isWe)+8)
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
		isWe(){
		  return this.$store.state.isLxIndex
		}
	},
	methods:{
		/**联系我们**/ 
		getContactUs(index){
			var that = this
			that.$api.getContactUs({
				params:{
					category:index
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.itemData = res.data.data[0]
				}else{
					that.itemData = {}
				}
			})
		},
		/**菜单点击按钮**/
		cilckMethod(index){
		  this.$store.commit('changLx',index.cur)
		  sessionStorage.setItem('changLx',index.cur)
		  this.getContactUs(index.cur+8)
		},
	}
}
</script>

<style scoped lang="scss">
.main-contents{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-view{
    width: 1200px;
    padding-top: 50px;
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
    .content{
      display: flex;
      justify-items: center;
      align-items: flex-start;
      padding-top: 20px;
      padding-bottom: 150px;
      .left{
        width: 285px;
        background: #FCFCFC;
        padding: 0px 0px 10px 0px;
      }
      .right{
        width: 895px;
        margin-left: 20px;
        .one{
          font-size: 15px;
          line-height: 20px;
          color: #333333;
        }
        .two{
          margin-top: 40px;
        }
        .three{
          font-size: 15px;
          line-height: 20px;
          text-align: justify;
          margin-top: 40px;
          color: #333333;

        }
      }
    }
  }

}
</style>